import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Daniel Mora</h1>
        <h2>Senior Software Engineer</h2>

        <section className="App-contact">
        <a
          href="https://linkedin.com/in/crantodev"
          target="_blank"
          rel="noreferrer"
        >
          Linkedin
        </a>

        <a
          href="https://github.com/crantodev"
          target="_blank"
          rel="noreferrer"
        >
          Github
        </a>
      </section>
      </header>
    </div>
  );
}

export default App;
